@font-face {
    font-family: "Yekan Medium";
    src: url(../Fonts/YekanBakhFaNum-Bold.woff2);
}

body{
    padding: 0;
    margin: 0;
    height: 100vh;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.language{
    margin-top: auto;
    text-align: center;
    padding: 4px;
    color: #024150;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: Barlow;
    text-wrap: nowrap;
}
.language:hover{
    background-color: #1393b3;
    color: aliceblue;
    border-radius: 2px;
}

.redirect{
    margin-bottom: -4px;
    margin-left: 10px;
    opacity: 0.7;
    color:#024150;
}
.redirect:hover{
    color:#ffffff;
}

.sidebar{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100vh;
    width: 40vh;
    background-color: rgb(241, 241, 241);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    position:fixed;
    box-sizing: border-box;
    overflow-y: auto;
}
.menu{
    margin: 0;
    padding: 5px;
    width: auto;
    height: 50px;
    font-family: "Yekan Medium";
    padding-right: 15px;
    color: #024150;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    
}
.soon{
    font-family: "Yekan Medium";
    text-align: center;
    margin-bottom: auto;
}
.menu:hover{
    background-color: #1393b3;
    color: aliceblue;
    border-radius: 2px;
}

.icon{
    opacity: 0.3;

}
.menu.active{
    background-color: #076179;
    color: aliceblue;
    cursor:not-allowed;

}
.menu:focus{
    outline: none;
}
.icon.active{
    color: rgb(255, 255, 255);
    opacity: 1;
}

@media (max-width: 960px) {
    .menu{
        width: auto;
        height: 30px;
        font-size: 11px;
        text-wrap: nowrap;
    }
    .language{
        font-size: 10px;
    }
    
}

@media (max-height: 600px) {
    .menu{
        width: auto;
        height: 30px;
        font-size: 10px;
        text-wrap: nowrap;
    }
    .language{
        font-size: 13px;
    }
    .sidebar{
        width: 50vh;
    }
}
@media (max-width: 520px) {
    .menu{
        width: auto;
        height: 20px;
        font-size: 10px;
        text-wrap: nowrap;
    }
    .language{
        font-size: 10px;
    }
    .sidebar{
        width: 20vh;
    }
}
