svg{
    border-radius: 10px;
}
.svg{
    font-family: Barlow;
    font-weight: bold;
}
@media (max-width: 960px) {
    svg{
        width: 15rem;
    }
}
@media (max-width: 520px) {
    svg{
        width: 10rem;
    }
}