@font-face {
    font-family: "YekanBakh Medium";
    src: url("../Fonts/YekanBakhFaNum-Medium.woff2") format('opentype');
}
@font-face {
    font-family: "YekanBakh Bold";
    src: url("../Fonts/YekanBakhFaNum-Bold.woff2") format('opentype');
}
@font-face {
    font-family: "YekanBakh Heavy";
    src: url("../Fonts/YekanBakhFaNum-Heavy.woff2") format('opentype');
}

.container{
    display: flex;
    justify-content: flex-start;
}
.content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    height: auto;
    flex-grow: 1;
    margin-right: 41vh;
}
.poster{
    background-color: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.096);
    display: flex;
    flex-direction: column;
    width: 20rem;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    margin: 20px;
    transition-duration: 0.5s;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.poster:hover{
    transform: scale(1.02);
    background-color: #1393b336;
}
*:focus{
    outline: none;
}
.image{
    width: 20rem;
    border-radius: 10px;
}
.description, .time , .reporter{
    text-align: center;
    color:#024150;
}
.description{
    font-family: "YekanBakh Heavy";
    font-size: 22px;
}
.reporter{
    font-family: "YekanBakh Bold";
    font-size: 16px;
}
.time {
    font-family: "YekanBakh Bold";
    font-size: 24px;
}
.total-time{
    font-family: "YekanBakh Medium";
    font-size: 19px;
    text-align: center;
    color: #024150;
}
.sub-title{
    font-family: "YekanBakh Bold";
    font-size: 16px;
    text-align: center;
    color: #024150;
}
.sadMan{
    height: 100vh;
}
.li{
    font-family: "YekanBakh Medium";
}
.page-title{
    text-align: center;
}
a{
    text-decoration: none;
}

@media (max-width: 960px) {
    .poster{
        width: 15rem;
        height: auto;
    }
    .image{
        width: 15rem;
    }

    .time , .reporter{
        font-family: "YekanBakh Bold";
        
    }
    .content{
        margin-right: 48vh;
    }
    .description{
        font-size: 19px;
    }
    .time{
        font-size: 20px;
    }
    .reporter{
        font-size: 14px;
    }
    .sub-title{
        font-size: 13px;
    }
    .total-time{
        font-size: 14px;;
    }
}
@media (max-width: 520px) {
    .content{
        margin-right: 21vh;
        
    }
    .poster{
        width: 10rem;
    }
    .image{
        width: 10rem;
    }
    .description{
        font-family: "YekanBakh Heavy";
        font-size: 19px;
    }
    .reporter{
        font-family: "YekanBakh Bold";
        font-size: 13px;
    }
    .time {
        font-family: "YekanBakh Bold";
        font-size: 21px;
    }
    .total-time{
        font-family: "YekanBakh Medium";
        font-size: 16px;
        text-align: center;
        color: #024150;
    }
}